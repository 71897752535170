import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../api"
import moment from "moment"
import { createUrl } from "@/store/stats/actions"
import { setOrderCardDateFilter, setOrderCardStatusFilter } from "@/store/orderCard/filters/actions"
import { resetModalModel } from "@/store/modal/actions"

export const SET_ORDER_CARD_DETAILS = 'SET_ORDER_CARD_DETAILS'
export const SET_ORDER_CARD_FETCHING = 'SET_ORDER_CARD_FETCHING'
export const SET_ORDER_CARDS = 'SET_ORDER_CARDS'

export const setOrderCardDetails = ({ item }) => (dispatch, getState) => {
  dispatch({ type: SET_ORDER_CARD_DETAILS, cardDetail: item })
}

export const createApiDefault = () => (dispatch, getState) => {
  const { filters } = getState().orderCard
  const { dates, search, status, pagination } = filters
  const { limit, page } = pagination
  let api = createParamsApi({ method: 'getOrdersCard', section: 'order', errorMessage: 'getOrdersCard' })
  api.params = {
    created_at_from: moment(dates.start, 'YYYY/MM/DD').format('DD.MM.YYYY'),
    created_at_to: moment(dates.end, 'YYYY/MM/DD').format('DD.MM.YYYY'),
    number: search,
    'status[]': status === 'all' ? '' : status,
    limit,
    start_position: page * limit
  }
  return api
}

export const getOrderCards = ({ getApi, set }) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDER_CARD_FETCHING, status: true })
  let api = getApi()(dispatch, getState)
  let data = await fetcher(api)
  set({ data })(dispatch, getState)
  dispatch({ type: SET_ORDER_CARD_FETCHING, status: false })
}

export const setOrderCardsDefault = ({ data }) => (dispatch, getState) => {
  const { club_card_orders, club_card_orders_count } = data
  const orderCards = {
    list: club_card_orders,
    totalListCount: club_card_orders_count,
  }
  dispatch({ type: SET_ORDER_CARDS, orderCards })
}

export const setPathByFilter = ({ router }) => (dispatch, getState) => {
  const { dates, search, status, pagination } = getState().orderCard.filters
  const { page } = pagination
  let asStr = createUrl({ dates, search, status, page })
  router.push('/order-card', '/order-card' + asStr, { shallow: true })
}

export const setQueryParam = ({ query = {} }) => (dispatch, getState) => {
  if (Object.keys(query).length === 0) return
  const { date_end, page, date_start, status } = query
  setOrderCardDateFilter({ dates: [ date_start, date_end ], onlySetFilter: true })(dispatch, getState)
  setOrderCardStatusFilter({ item: { id: status }, onlySetFilter: true })(dispatch, getState)
}

export const updateOrder = ({ id, comment = '', status }) => async (dispatch, getState) => {
  await fetcher(createParamsApi({ method: 'updateOrder', section: 'order', apiUrlParams: { id, comment, status } }))
  resetModalModel()(dispatch, getState)
  await getOrderCards({ getApi: createApiDefault, set: setOrderCardsDefault })(dispatch, getState)
}

export const uploadToExcel = () => async (dispatch, getState) => {
  await getOrderCards({ getApi: createUploadApi, set: downloadFile })(dispatch, getState)
}

export const downloadFile = ({ data }) => async (dispatch, getState) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.download = 'card_orders.xlsx'
  link.click()
}

export const createUploadApi = () => (dispatch, getState) => {
  const api = createApiDefault()(dispatch, getState)
  api.params.export = 'excel'
  api.isFormData = true
  api.responseType = 'blob'
  return api
}
