import { createReducer } from "@/utils/store"
import {
  SET_AUTH_STATUS,
  CHANGE_SIGN_IN_FETCHING,
  SET_SIGN_IN_INFO,
  SET_AUTH_START_AUTH,
  SET_SIGN_IN_ERROR
} from "@/store/user/auth/actions"

const defaultValue = {
  isAuth: false,
  fetch: false,
  startAuth: false,
  isError: false,
  signInInfo: {
    club: {
      lk_settings: {
        manual_search: false
      },
      logo: '',
      title: ''
    }
  },
}

export default createReducer(defaultValue, {
  [ SET_AUTH_STATUS ]: (state, { status }) => ({ ...state, isAuth: status }),
  [ SET_SIGN_IN_ERROR ]: (state, { status }) => ({ ...state, isError: status }),
  [ SET_AUTH_START_AUTH ]: (state, { status }) => ({ ...state, startAuth: status }),
  [ CHANGE_SIGN_IN_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_SIGN_IN_INFO ]: (state, { info }) => ({ ...state, signInInfo: { ...state.signInInfo, ...info } }),
})
