import styles from './MobileSideBar.module.scss'
import { connect } from 'react-redux'
import routes from '../../../routes'
import SearchSignIn from '../../../assets/icons/leftSideBar/search-clubs.svg'
import Toggle from '../../../assets/icons/leftSideBar/toggle.svg'
import Phone from '../../../assets/icons/leftSideBar/phone.svg'
import Link from "next/link"
import { openModal } from "@/store/modal/actions"
import Fitness from '../../../assets/icons/leftSideBar/fitness.svg'
import Like from '../../../assets/icons/leftSideBar/like.svg'
import Blog from '../../../assets/icons/leftSideBar/blog.svg'
import Friend from '../../../assets/icons/leftSideBar/friend.svg'
import HousePlus from '../../../assets/icons/leftSideBar/house-plus.svg'
import Support from '../../../assets/icons/voucher/support.svg'
import Statistics from '../../../assets/icons/leftSideBar/statistics.svg'
import Card from '../../../assets/icons/leftSideBar/card.svg'

const Images = {
  SearchSignIn: <SearchSignIn/>,
  Like: <Like/>,
  Blog: <Blog/>,
  Friend: <Friend/>,
  HousePlus: <HousePlus/>,
  Support: <Support/>,
  Phone: <Phone/>,
  Fitness: <Fitness/>,
  Statistics: <Statistics/>,
  Card: <Card/>,
}

const { mobileSideBar } = routes

const MobileSideBar = ({ modalNames, openModal, signInInfo }) => {

  const { logo, title } = signInInfo.club

  let sideBar = mobileSideBar

  if (signInInfo.is_manager) {
    sideBar = [
      {
        href: '/statistics',
        as: '/statistics',
        title: 'Статистика',
        icon: 'Statistics'
      },
      {
        href: '/order-card',
        as: '/order-card',
        title: 'Клубные карты',
        icon: 'Card'
      },
    ]
  }

  return (
    <>
      <div className={ styles.root }>
        { sideBar.map((item, index) =>
          !item.href ?
            <span className={ styles.item } key={ index }>
                { Images[ item.icon ] }
              <span>{ item.title }</span>
              </span> : item.isNativeLink ?
              <a className={ styles.item } href={ item.href } target='_blank' rel="noopener noreferrer" key={ index }>
                { Images[ item.icon ] }
                <span>{ item.title }</span>
              </a> :
              <Link as={ item.as } href={ item.href } key={ index }>
                <a className={ styles.item }>
                  { Images[ item.icon ] }
                  <span>{ item.title }</span>
                </a>
              </Link>
        ) }
        <div className={ styles.item + ' ' + styles.rootMenu } onClick={ openModal.bind(null, modalNames.mobileMenu) }>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="4.3204" cy="4.32065" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="4.31991" cy="11.9998" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="4.31991" cy="19.68" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="11.9996" cy="4.31991" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="11.9996" cy="11.9998" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="11.9996" cy="19.68" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="19.6798" cy="4.31991" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="19.6798" cy="11.9998" rx="1.92001" ry="1.92001" fill="#333333"/>
            <ellipse cx="19.6798" cy="19.68" rx="1.92001" ry="1.92001" fill="#333333"/>
          </svg>
          <div className={ 'body3' }>Меню</div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  modalNames: state.modal.modalNames,
  signInInfo: state.user.auth.signInInfo
})

const mapDispatchToProps = dispatch => ({
  openModal: (name) => dispatch(openModal(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSideBar)
