import moment from "moment"
import { createReducer } from "@/utils/store"
import {
  SET_ORDER_CARD_PAGINATION,
  SET_ORDER_CARD_DATE_FILTER,
  SET_ORDER_CARD_SEARCH,
  SET_ORDER_CARD_STATUS_FILTER,
  RESET_ORDER_CARD_DATE_FILTER
} from "@/store/orderCard/filters/actions"

const initial = {
  dates: {
    start: moment().subtract(3, 'months').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD')
  },
  status: 'all',
  search: '',
  pagination: {
    limit: 20,
    page: 0
  },
}

export default createReducer(initial, {
  [ SET_ORDER_CARD_PAGINATION ]: (state, { data }) => ({ ...state, pagination: { ...state.pagination, ...data } }),
  [ SET_ORDER_CARD_DATE_FILTER ]: (state, { dates }) => ({ ...state, dates }),
  [ SET_ORDER_CARD_STATUS_FILTER ]: (state, { status }) => ({ ...state, status }),
  [ SET_ORDER_CARD_SEARCH ]: (state, { value }) => ({ ...state, search: value }),
  [ RESET_ORDER_CARD_DATE_FILTER ]: (state) => ({ ...state, dates: JSON.parse(JSON.stringify(initial.dates)) }),
})
