import { connect } from 'react-redux'
import Auth from "./Auth/Auth"
import LinkList from "./LinkList/LinkList"
import styles from './LeftSideBar.module.scss'
import Link from "next/link"
import routes from "../../../routes"
import SearchSignIn from '../../../assets/icons/leftSideBar/search-clubs.svg'
import Like from '../../../assets/icons/leftSideBar/like.svg'
import Blog from '../../../assets/icons/leftSideBar/blog.svg'
import Friend from '../../../assets/icons/leftSideBar/friend.svg'
import HousePlus from '../../../assets/icons/leftSideBar/house-plus.svg'
import Support from '../../../assets/icons/voucher/support.svg'
import Phone from '../../../assets/icons/leftSideBar/phone.svg'
import Fitness from '../../../assets/icons/leftSideBar/fitness.svg'
import Statistics from '../../../assets/icons/leftSideBar/statistics.svg'
import Card from '../../../assets/icons/leftSideBar/card.svg'
import { Logo } from "@/layouts/Logo/Logo"
import { logOut } from "@/store/user/auth/actions"
import { useRouter } from "next/router"

const Images = {
  SearchSignIn: <SearchSignIn/>,
  Like: <Like/>,
  Blog: <Blog/>,
  Friend: <Friend/>,
  HousePlus: <HousePlus/>,
  Support: <Support/>,
  Phone: <Phone/>,
  Fitness: <Fitness/>,
  Statistics: <Statistics/>,
  Card: <Card/>,
}


let { leftSideBar } = routes

const LeftSideBar = ({
                       leftMenuIsOpen,
                       logo = <Logo/>,
                       isAuth,
                       singInInfo,
                       logOut,
                     }) => {

  const authInfo = {
    isAuth,
    number: singInInfo.club.title,
    img: singInInfo.club.logo,
    is_manager: singInInfo.is_manager
  }

  const leftSideBarFormatted = leftSideBar.map(item => item.isInstruction ? ({
    ...item,
    href: singInInfo.club?.help_page?.href,
    as: singInInfo.club?.help_page?.href
  }) : item)

  const router = useRouter()

  return (
    <div className={ styles.root + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }>
      <div className={ styles.container }>
        <div className={ styles.toggle }>
          <div className={ styles.logo }>
            <Link href={ '/' }>
              <a>
                { logo }
              </a>
            </Link>
          </div>
        </div>
        <Auth authInfo={ authInfo }
              signOut={ logOut.bind(null, router) }/>
        <LinkList authInfo={ authInfo } leftSideBar={ leftSideBarFormatted } Images={ Images }/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  leftMenuIsOpen: state.global.leftMenuIsOpen,
  windowInnerWidth: state.window.size.windowInnerWidth,
  isAuth: state.user.auth.isAuth,
  singInInfo: state.user.auth.signInInfo
})

const mapDispatchToProps = dispatch => ({
  logOut: (router) => dispatch(logOut(router)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar)
