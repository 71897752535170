import { createReducer } from "@/utils/store"

import {
  SET_ORDERS,
  SET_STARTED_ORDERS,
  SET_ORDERS_FETCHING,
  SET_ORDER_SELECTED_STATUS,
  SET_ORDER_SEARCH,
  SET_COMPLETED_ORDER_ID
} from "@/store/order/actions"

const initial = {
  list: [],
  startedCount: null,
  selectedStatus: 0,
  search: '',
  fetch: false,
  completedStatusId: 'completed',
  selectedStatusList: {
    0: {
      title: 'Ожидают входа',
      id: 0,
      href: 'order-started'
    },
    1:
      {
        title: 'Сегодняшние',
        id: 1,
        href: 'order-finished'
      }
  }
}

export default createReducer(initial, {
  [ SET_ORDERS ]: (state, { orders }) => ({ ...state, list: orders }),
  [ SET_COMPLETED_ORDER_ID ]: (state, { id }) => ({ ...state, completedStatusId: id }),
  [ SET_STARTED_ORDERS ]: (state, { startedCount }) => ({ ...state, startedCount }),
  [ SET_ORDERS_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_ORDER_SELECTED_STATUS ]: (state, { status }) => ({ ...state, selectedStatus: status }),
  [ SET_ORDER_SEARCH ]: (state, { value }) => ({ ...state, search: value }),
})
