import { createParamsApi } from "../../api"
import { fetcher } from "@/utils/fetcher"
import moment from "moment"

export const SET_STATS_DATE_FILTER = 'SET_STATS_DATE_FILTER'
export const SET_STATS = 'SET_STATS'
export const SET_STATS_PAGINATION = 'SET_STATS_PAGINATION'
export const SET_STATS_FETCHING = 'SET_STATS_FETCHING'
export const SET_STATS_STATUS_FILTER = 'SET_STATS_STATUS_FILTER'
export const SET_STATS_SEARCH = 'SET_STATS_SEARCH'
export const RESET_STATS_DATE_FILTER = 'RESET_STATS_DATE_FILTER'

export const resetStatsDateFilter = () => ({
  type: RESET_STATS_DATE_FILTER, dates: {
    start: moment().subtract(3, 'months').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD')
  }
})

export const setStatsDateFilter = ({ dates, router, onlySetFilter }) => async (dispatch, getState) => {
  dispatch({ type: SET_STATS_DATE_FILTER, dates: { start: dates[ 0 ], end: dates[ 1 ] } })
  if (dates[ 1 ] && !onlySetFilter) await setStatsPagination({ router })(dispatch, getState)
}

export const setStatsStatusFilter = ({ item, router, onlySetFilter }) => async (dispatch, getState) => {
  if (item.id) dispatch({ type: SET_STATS_STATUS_FILTER, status: item.id })
  if (!onlySetFilter) await setStatsPagination({ router })(dispatch, getState)
}

export const setStatsPagination = ({ page = 0, router } = {}) => async (dispatch, getState) => {
  dispatch({ type: SET_STATS_PAGINATION, data: { page } })
  if (router) setPathByFilter({ router })(dispatch, getState)
  await getStats({ getApi: createApiDefault, set: setDefaultStats })(dispatch, getState)
}

export const changeSearch = value => (dispatch, getState) => {
  dispatch({ type: SET_STATS_FETCHING, status: true })
  dispatch({ type: SET_STATS_SEARCH, value })
  setTimeout(() => {
    if (value === getState().stats.search) {
      setStatsPagination()(dispatch, getState)
    }
  }, 300)
}

export const getStats = ({ getApi, set }) => async (dispatch, getState) => {
  dispatch({ type: SET_STATS_FETCHING, status: true })
  let api = getApi()(dispatch, getState)
  let data = await fetcher(api)
  set({ data })(dispatch, getState)
  dispatch({ type: SET_STATS_FETCHING, status: false })
}

export const setDefaultStats = ({ data }) => (dispatch, getState) => {
  const { orders, orders_count } = data
  const stats = {
    list: orders,
    totalListCount: orders_count,
  }
  dispatch({ type: SET_STATS, stats })
}

export const createApiDefault = () => (dispatch, getState) => {
  const { dates, search, status, pagination } = getState().stats
  const { limit, page } = pagination
  let api = createParamsApi({ method: 'getStats', section: 'stats', errorMessage: 'getStatsApi' })
  api.params = {
    started_at_from: moment(dates.start, 'YYYY/MM/DD').format('DD.MM.YYYY'),
    started_at_to: moment(dates.end, 'YYYY/MM/DD').format('DD.MM.YYYY'),
    number: search,
    status: status === 'all' ? '' : status,
    limit,
    start_position: page * limit
  }
  return api
}

export const uploadToExcel = () => async (dispatch, getState) => {
  await getStats({ getApi: createUploadApi, set: downloadFile })(dispatch, getState)
}

export const downloadFile = ({ data }) => async (dispatch, getState) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.download = 'orders.xlsx'
  link.click()
}

export const createUploadApi = () => (dispatch, getState) => {
  const api = createApiDefault()(dispatch, getState)
  api.params.export = 'excel'
  api.isFormData = true
  api.responseType = 'blob'
  return api
}

export const setPathByFilter = ({ router }) => (dispatch, getState) => {
  const { dates, search, status, pagination } = getState().stats
  const { page } = pagination
  let asStr = createUrl({ page, dates, status, search })
  router.push('/statistics', '/statistics' + asStr, { shallow: true })
}

export const createUrl = ({ page, dates, status, search }) => {
  let asStr = (page >= 1 ? '&page=' + (Number(page) + 1) : '') +
    (dates.end ? '&date_start=' + dates.start + '&date_end=' + dates.end : '') +
    (status !== 'all' ? '&status=' + status : '') +
    (search ? '&search=' + search : '')
  if (asStr.length && asStr[ 0 ] !== '?') asStr = '?' + asStr.slice(1, asStr.length)
  return asStr
}

export const setQueryParam = ({ query = {} }) => (dispatch, getState) => {
  if (Object.keys(query).length === 0) return
  const { date_end, page, date_start, status } = query
  setStatsDateFilter({ dates: [ date_start, date_end ], onlySetFilter: true })(dispatch, getState)
  setStatsStatusFilter({ item: { id: status }, onlySetFilter: true })(dispatch, getState)
}
