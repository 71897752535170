import { createReducer } from "@/utils/store"

import {
  CHANGE_MODAL_OPEN_STATE,
  CHANGE_MODAL_NAME,
  RESET_MODAL_MODEL,
  SET_MODAL_ADDITIONAL,
  RESET_MODAL_ADDITIONAL
} from "./actions"

const initial = {
  isOpen: false,
  modalName: '',
  additionalParams: {
    className: '',
  },
  modalNames: {
    mobileMenu: 'MobileMenu',
    dateFilter: 'DateFilter',
    orderCardDetails: 'OrderCardDetails'
  }
}

export default createReducer(initial, {
  [ CHANGE_MODAL_OPEN_STATE ]: (state, { status }) => ({ ...state, isOpen: status }),
  [ RESET_MODAL_ADDITIONAL ]: (state) => ({
    ...state,
    additionalParams: JSON.parse(JSON.stringify(initial.additionalParams))
  }),
  [ SET_MODAL_ADDITIONAL ]: (state, { additional }) => ({
    ...state,
    additionalParams: { ...state.additionalParams, ...additional }
  }),
  [ CHANGE_MODAL_NAME ]: (state, { name }) => ({ ...state, modalName: name }),
  [ RESET_MODAL_MODEL ]: (state) => ({
    ...JSON.parse(JSON.stringify(initial)),
    additionalParams: state.additionalParams,
    modalName: state.modalName
  })
})
