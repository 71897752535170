import Head from "next/head"
import fav from '../../../assets/icons/fav.png'
import { connect } from 'react-redux'

const Meta = ({}) => {
  return (
    <Head>
      <meta charSet={ 'utf-8' }/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover  maximum-scale=1"/>
      <title>ЛК UPass</title>
      <link rel="shortcut icon" href={ fav } type="image/x-icon"/>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
            rel="preload" as={ 'style' } onLoad="this.onload=null;this.rel='stylesheet'"/>
      <noscript>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"/>
      </noscript>
      <script
        dangerouslySetInnerHTML={ {
          __html: `
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/enwnhr59'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/enwnhr59';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `
        } }/>
    </Head>
  )
}

const mapStateToProps = state => (
  {
    meta: state.global.meta.meta_tags
  }
)

export default connect(mapStateToProps)(Meta)
