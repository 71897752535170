import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import Router from 'next/router'
import { connect } from 'react-redux'
import dynamic from "next/dynamic"
import { bindActionCreators } from 'redux'
import { checkIsAuth, redirectAuthUser } from "@/store/user/auth/actions"
import { changeWindowSize } from "@/store/window/actions"
import { changeLocationOrigin } from "@/store/window/actions"
import App from 'next/app'
import Root from "../components/Root"
import withReduxStore from '../utils/with-redux-store'
import '../gdelu-react-ui/src/assets/style/indents.scss'
import cookies from "next-cookies"
import LeftSideBar from "../components/Layouts/LeftSideBar/LeftSideBar"
import MobileSideBar from "@/layouts/MobileSideBar/MobileSideBar"
import '../assets/style/app.scss'
import { setUserAgent } from "@/store/window/actions"
import Meta from "@/layouts/Meta/Meta"
import axios from 'axios'
import { getUserInfo } from "@/store/user/auth/actions"
import moment from "moment"
import { checkTokenCookie } from "@/store/user/auth/actions"
import { getGlobal } from "@/store/global/actions"

moment.lang('ru')

const Modal = dynamic(() => import('@/components/Modal/Modal'), { ssr: false })
const Error = dynamic(() => import('./404'), { ssr: false })
import { openModal } from "@/store/modal/actions"
import routes from "../routes"

Router.events.on('routeChangeComplete', (path) => {
  try {
    document.getElementById('App').scrollTop = 0
    const isPrivateRoute = routes.checkPrivateRoute(Router.pathname)
    if (isPrivateRoute) checkIsAuth({ router: Router })
  } catch (err) {
    console.log(err)
  }
  try {
    window.Intercom("update")
  } catch (err) {
    console.log(err)
  }
})

class MyApp extends App {

  async componentDidMount() {
    window.Intercom('boot', { app_id: 'enwnhr59', })
    this.timeOutCheckToken()
  }

  timeOutCheckToken() {
    setTimeout(() => {
      this.props.checkTokenCookie({ router: this.props.router })
      this.timeOutCheckToken()
    }, 5000)
  }

  render() {
    const {
      Component,
      pageProps,
      store,
      router,
      leftMenuIsOpen,
      windowInnerWidth,
      sizes
    } = this.props

    const isAuthPage = router.pathname.indexOf('auth') >= 0

    return (
      <Provider store={ store }>
        <Root>
          <>
            <Meta/>
            <div id='root-app'>
              { isAuthPage ?
                <Fragment>
                  { pageProps.error && pageProps.error.status ?
                    <Error { ...pageProps }/> :
                    <Component { ...pageProps }/> }
                </Fragment> : <Fragment>
                  { windowInnerWidth > sizes.mob ? <LeftSideBar/> : <MobileSideBar/> }
                  <div id='root-app-container' className={ leftMenuIsOpen ? 'root-app-container-open' : '' }>
                    <main className={ router.pathname === '/' ? 'main-orders' : '' }>
                      { pageProps.error && pageProps.error.status ? <Error { ...pageProps }/> :
                        <Component { ...pageProps }/> }
                    </main>
                  </div>
                </Fragment> }
            </div>
            <Modal/>
          </>
        </Root>
      </Provider>
    )
  }
}

MyApp.getInitialProps = async (appContext) => {
  let appProps
  const { ctx } = appContext
  const { store, res, req, asPath, pathname, query, router } = ctx
  const { dispatch, getState } = store
  const isServer = !!ctx.req
  let { token } = cookies(ctx)
  axios.defaults.headers.Authorization = token ? 'Token ' + (token || '') : ''

  try {
    let host = null
    if (isServer) {
      dispatch(changeWindowSize({
        width: (ctx.req.headers[ 'user-agent' ]).match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        ) === null ? 1800 : store.getState().window.sizes.mob, height: 300
      }))
      dispatch(setUserAgent(ctx.req.headers[ 'user-agent' ]))

      host = ctx.req.headers.host
      if (host.indexOf(':') > 0 && host !== 'localhost:3000') host = host.slice(0, host.indexOf(':'))
      if (host.indexOf('https://') < 0 && host !== 'localhost:3000') host = 'https://' + host
    }

    if (!!host) dispatch(changeLocationOrigin(host))
    if (isServer) await dispatch(getUserInfo())
    dispatch(redirectAuthUser({ res, router: Router, pathname }))

    const isPrivateRoute = routes.checkPrivateRoute(pathname)
    if (isPrivateRoute) {
      let isAccess = dispatch(checkIsAuth({ res, router: Router }))
      if (!isAccess) return {}
    }


    appProps = await App.getInitialProps(appContext)

    const asyncAllMethod = () => {
      let allMethods = [
        dispatch(getGlobal()),
      ]
      return Promise.all(allMethods)
    }

    if (isServer) {
      await asyncAllMethod()
    }
    else {
      asyncAllMethod()
    }

    if (isServer) {
      if (appProps.pageProps.error && !!appProps.pageProps.error.status) {
        res.statusCode = 404
      }
    }

    return { ...appProps }
  } catch
    (e) {
    // setSentryMessage('\x1b[31m%s\x1b[0m', e + ' GLOBAL APP ERROR');
    console.error('\x1b[31m%s\x1b[0m', e + ' GLOBAL APP ERROR')

    if (isServer) {
      res.statusCode = 404
    }
    return { pageProps: { error: { status: 404 } } }
  }
}

const mapStateToProps = state => {
  return {
    leftMenuIsOpen: state.global.leftMenuIsOpen,
    windowInnerWidth: state.window.size.windowInnerWidth,
    userAgent: state.window.userAgent,
    modalNames: state.modal.modalNames,
    sizes: state.window.sizes,
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  checkTokenCookie: bindActionCreators(checkTokenCookie, dispatch),
})

export default withReduxStore(connect(mapStateToProps, mapDispatchToProps)(MyApp))
