import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../api"

export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS'
export const TOGGLE_LEFT_MENU_STATE = 'TOGGLE_LEFT_MENU_STATE'
export const SET_TERMS_OR_PRIVACY = 'SET_TERMS_OR_PRIVACY'
export const SET_GLOBAL_META = 'SET_GLOBAL_META'

export const getGlobal = ({
                            setSettings = setSettingsDefault
                          } = {}) => async (dispatch, getState) => {
  let api = createParamsApi({ method: 'getGlobal', errorMessage: 'getGlobal', section: 'global' })
  let data = await fetcher(api)
  setSettings({ data })(dispatch, getState)
}

export const toggleMenuState = () => (dispatch, getState) => {
  const { leftMenuIsOpen } = getState().global
  console.log(leftMenuIsOpen)
  dispatch({ type: TOGGLE_LEFT_MENU_STATE, status: !leftMenuIsOpen })
}

const setSettingsDefault = ({ data }) => (dispatch, getState) => {
  dispatch({ type: SET_DEFAULT_SETTINGS, settings: data })
}
