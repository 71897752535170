import { memo } from 'react'
import { connect } from 'react-redux'
import Link from "next/link"
import { withRouter } from "next/router"
import styles from './LinkList.module.scss'

const LinkList = memo(({ router, leftMenuIsOpen, leftSideBar, Images, onClick, hideActiveLine, authInfo }) => {
  const { pathname } = router
  let activeElementIndex = null

  let sideBar = leftSideBar
  if(authInfo.is_manager){
    sideBar = [
      {
        href: '/order-card',
        as: '/order-card',
        title: 'Клубные карты',
        icon: 'Card'
      },
      {
        href: '/statistics',
        as: '/statistics',
        title: 'Статистика',
        icon: 'Statistics'
      }
    ]
  }

  sideBar.filter(item => item.isAuth && authInfo.isAuth || !item.isAuth).forEach((item, index) => {
    console.log(item.href, pathname + (pathname !== '/' ? '/' : ''))
    if (item.href === pathname) activeElementIndex = index
  })
  const handlers = {}
  if (onClick) handlers.onClick = onClick

  const topStyle = (17 * activeElementIndex + (activeElementIndex * 30)) || -3
  return (
    <div className={ styles.root + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }>
      <div className={ styles.container }>
        { sideBar.map((item, index) =>
          (item.isAuth && authInfo.isAuth || !item.isAuth) ?
            <div
              className={ styles.item + (pathname === item.href ? ' ' + styles.active : '') }
              key={ index }>
              { !item.href ?
                <span className={ styles.title } { ...handlers }>
                { Images[ item.icon ] }
                  <span>{ item.title }</span>
              </span> : item.isNativeLink ?
                  <a className={ styles.title } href={ item.href } target='_blank' rel="noopener noreferrer">
                    { Images[ item.icon ] }
                    <span>{ item.title }</span>
                  </a> :
                  <Link as={ item.as } href={ item.href }>
                    <a className={ styles.title }  { ...handlers }>
                      { Images[ item.icon ] }
                      <span>{ item.title }</span>
                    </a>
                  </Link> }
            </div> : null
        ) }
        { hideActiveLine ? null : <div className={ styles.activeLine }
                                       style={ typeof activeElementIndex !== 'object' ? { top: topStyle + 'px' } : { opacity: 0 } }/> }
      </div>
    </div>
  )
})

const mapStateToProps = state => ({
  leftMenuIsOpen: state.global.leftMenuIsOpen
})

export default withRouter(connect(mapStateToProps)(LinkList))
