export const CHANGE_WINDOW_SIZE = 'CHANGE_WINDOW_SIZE'
export const CHANGE_LOCATION_ORIGIN = 'CHANGE_LOCATION_ORIGIN'
export const CHANGE_WINDOW_SCROLL = 'CHANGE_WINDOW_SCROLL'
export const SET_USER_AGENT = 'SET_USER_AGENT'

export const changeWindowSize = ({ width, height } = {}) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_WINDOW_SIZE,
    size: {
      windowInnerHeight: height || window.innerHeight,
      windowInnerWidth: width || window.innerWidth
    }
  })
}

export const setUserAgent = agent => ({ type: SET_USER_AGENT, agent })

export const changeWindowScroll = () => {
  return {
    type: CHANGE_WINDOW_SCROLL,
    scroll: {
      pageYOffset: window.pageYOffset
    }
  }
}

export const changeLocationOrigin = (hostname) => ({
  type: CHANGE_LOCATION_ORIGIN,
  origin: hostname || window.location.origin
})
