import { createReducer } from "../../utils/store"
import { CHANGE_WINDOW_SIZE, CHANGE_LOCATION_ORIGIN, CHANGE_WINDOW_SCROLL, SET_USER_AGENT } from './actions'

const defaultProps = {
  size: {},
  location: {
    origin: ''
  },
  scroll: {},
  sizes: {
    mediumD: 1600,
    smallD: 1200,
    tab: 950,
    mob: 750
  },
  userAgent: ''
}

export default createReducer(defaultProps, {
  [ CHANGE_WINDOW_SIZE ]: (state, { size }) => ({ ...state, size }),
  [ CHANGE_LOCATION_ORIGIN ]: (state, { origin }) => ({ ...state, location: { ...state.location, origin } }),
  [ CHANGE_WINDOW_SCROLL ]: (state, { scroll }) => ({ ...state, scroll }),
  [ SET_USER_AGENT ]: (state, { agent }) => ({ ...state, userAgent: agent }),
})
