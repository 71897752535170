import { SET_ORDER_CARD_FETCHING } from "@/store/orderCard/global/actions"
import { getOrderCards, setOrderCardsDefault, createApiDefault, setPathByFilter } from "@/store/orderCard/global/actions"
import moment from "moment"

export const SET_ORDER_CARD_SEARCH = 'SET_ORDER_CARD_SEARCH'
export const SET_ORDER_CARD_PAGINATION = 'SET_ORDER_CARD_PAGINATION'
export const RESET_ORDER_CARD_DATE_FILTER = 'RESET_ORDER_CARD_DATE_FILTER'
export const SET_ORDER_CARD_DATE_FILTER = 'SET_ORDER_CARD_DATE_FILTER'
export const SET_ORDER_CARD_STATUS_FILTER = 'SET_ORDER_CARD_STATUS_FILTER'

export const resetOrderCardDateFilter = () => ({
  type: RESET_ORDER_CARD_DATE_FILTER, dates: {
    start: moment().subtract(3, 'months').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD')
  }
})

export const setOrderCardDateFilter = ({ dates, router, onlySetFilter }) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDER_CARD_DATE_FILTER, dates: { start: dates[ 0 ], end: dates[ 1 ] } })
  if (dates[ 1 ] && !onlySetFilter) await setOrderClubPagination({ router })(dispatch, getState)
}

export const setOrderCardStatusFilter = ({ item, router, onlySetFilter }) => async (dispatch, getState) => {
  if (item.id) dispatch({ type: SET_ORDER_CARD_STATUS_FILTER, status: item.id })
  if (!onlySetFilter) await setOrderClubPagination({ router })(dispatch, getState)
}

export const setOrderClubPagination = ({ page = 0, router } = {}) => async (dispatch, getState) => {
  dispatch({ type: SET_ORDER_CARD_PAGINATION, data: { page } })
  if (router) setPathByFilter({ router })(dispatch, getState)
  await getOrderCards({ getApi: createApiDefault, set: setOrderCardsDefault })(dispatch, getState)
}

export const changeSearch = value => (dispatch, getState) => {
  dispatch({ type: SET_ORDER_CARD_FETCHING, status: true })
  dispatch({ type: SET_ORDER_CARD_SEARCH, value })
  setTimeout(() => {
    if (value === getState().stats.search) {
      setOrderClubPagination()(dispatch, getState)
    }
  }, 300)
}
