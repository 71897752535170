import { combineReducers } from 'redux'
import user from '../user/reducer'
import window from '../window/reducer'
import global from '../global/reducer'
import modal from '../modal/reducer'
import order from '../order/reducer'
import stats from '../stats/reducer'
import orderCard from '../orderCard/reducer'

const combinedReducer = combineReducers({
  user,
  window,
  global,
  modal,
  order,
  stats,
  orderCard
})

export default combinedReducer
