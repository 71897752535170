import { createReducer } from "@/utils/store"
import {
  SET_STATS_DATE_FILTER,
  RESET_STATS_DATE_FILTER,
  SET_STATS_STATUS_FILTER,
  SET_STATS_FETCHING,
  SET_STATS_SEARCH,
  SET_STATS_PAGINATION,
  SET_STATS
} from "@/store/stats/actions"
import moment from "moment"

const initial = {
  fetch: false,
  dates: {
    start: moment().subtract(3, 'months').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD')
  },
  status: 'all',
  search: '',
  pagination: {
    limit: 20,
    page: 0
  },
  stats: {
    list: [],
    totalListCount: null,
    revenueSum: null
  }
}

export default createReducer(initial, {
  [ SET_STATS_DATE_FILTER ]: (state, { dates }) => ({ ...state, dates }),
  [ SET_STATS_STATUS_FILTER ]: (state, { status }) => ({ ...state, status }),
  [ SET_STATS_SEARCH ]: (state, { value }) => ({ ...state, search: value }),
  [ SET_STATS_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ RESET_STATS_DATE_FILTER ]: (state, { dates }) => ({ ...state, dates }),
  [ SET_STATS_PAGINATION ]: (state, { data }) => ({ ...state, pagination: { ...state.pagination, ...data } }),
  [ SET_STATS ]: (state, { stats }) => ({ ...state, stats }),
})
