import { createReducer } from "@/utils/store"

import { SET_ORDER_CARD_DETAILS, SET_ORDER_CARDS, SET_ORDER_CARD_FETCHING } from "@/store/orderCard/global/actions"

const initial = {
  fetch: false,
  list: [],
  cardDetail: {},
  totalListCount: null,
}

export default createReducer(initial, {
  [ SET_ORDER_CARD_DETAILS ]: (state, { cardDetail }) => ({ ...state, cardDetail }),
  [ SET_ORDER_CARD_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_ORDER_CARDS ]: (state, { orderCards }) => ({
    ...state,
    list: orderCards.list,
    totalListCount: orderCards.totalListCount
  }),
})
