import { createReducer } from "@/utils/store"

import {
  SET_DEFAULT_SETTINGS,
  TOGGLE_LEFT_MENU_STATE,
  SET_TERMS_OR_PRIVACY,
  SET_GLOBAL_META,
} from "./actions"

const initial = {
  support: {
    phone: null,
    email: null
  },
  leftMenuIsOpen: true,
  privacy: null,
  agreement: null,
  meta: {
    meta_tags: {}
  }
}

export default createReducer(initial, {
  [ SET_DEFAULT_SETTINGS ]: (state, { settings }) => ({ ...state, ...settings }),
  [ TOGGLE_LEFT_MENU_STATE ]: (state, { status }) => ({ ...state, leftMenuIsOpen: status }),
  [ SET_TERMS_OR_PRIVACY ]: (state, { data }) => ({ ...state, ...data }),
  [ SET_GLOBAL_META ]: (state, { data }) => ({ ...state, meta: data }),
})
